import xtremeVC from "@xtreme-controls/vue-components";
import Vue from "vue";
import VueMatomo from "vue-matomo";
import IdleVue from "idle-vue";
import { InlineSvgPlugin } from "vue-inline-svg";
import i18n from "./plugins/i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import packageJson from "../package.json";
import dayjs from "./plugins/dayjs";
import { getEnvString, envInt } from "./envUtils";

const { version } = packageJson;
console.info(`App Version: ${version}`);

Vue.config.productionTip = false;

const hostURL = getEnvString("$VUE_APP_MATOMO_HOST_URL", "https://logs.xcai.co.uk/");
const siteId = envInt(getEnvString("$VUE_APP_MATOMO_SITE_ID", "2"));

Vue.use(xtremeVC);
Vue.use(dayjs);
Vue.use(InlineSvgPlugin);
Vue.use(IdleVue, {
    eventEmitter: new Vue(),
    idleTime: 8 * 60 * 60 * 1000, // TODO: should be in env var
});

Vue.use(VueMatomo, {
    host: hostURL,
    siteId,
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");

// eslint-disable-next-line no-underscore-dangle
window._paq.push(["trackPageView"]);
